import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";

import { WagmiProvider } from "wagmi";
import { pulsechain } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { defineChain } from "viem";

const queryClient = new QueryClient();

const projectId = "72c7539b3b77af0a111da956c87c4f06";

const metadata = {
  name: "HEX1",
  description:
    "Hex One ($HEX1) is a yield-bearing stablecoin backed by Hex T-Shares",
  url: "https://test.hex1.club",
  icons: ["https://test.hex1.club/apple-touch-icon.png"],
};

export const myLocalChain = defineChain({
  id: 369,
  name: "Local PulseChain",
  nativeCurrency: { name: "Pulse", symbol: "PLS", decimals: 18 },
  testnet: true,
  rpcUrls: {
    default: {
      http: ["http://localhost:8545"],
    },
  },
});

const chains = [pulsechain] as const;

const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  enableWalletConnect: true, // Optional - true by default
  enableInjected: true, // Optional - true by default
  enableEIP6963: true, // Optional - true by default
  enableCoinbase: true, // Optional - true by default
});

createWeb3Modal({
  wagmiConfig,
  projectId,
  themeVariables: {
    "--w3m-font-family": "Poppins, sans-serif",
    "--w3m-accent": "#fd5bb0",
  },
});

export function Web3ModalProvider({ children }: { children: React.ReactNode }) {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}
