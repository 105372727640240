import { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";
import ConnectButton from "components/Common/ConnectButton";
import { IoIosMenu, IoIosClose } from "react-icons/io";

const menus = [
  { label: "Borrow", url: "/borrow" },
  { label: "Bootstrap", url: "/bootstrap" },
  { label: "Airdrop", url: "/airdrop" },
  { label: "Farming", url: "/farming" },
  { label: "Price Feed", url: "/price-feed" },
  { label: "Stats", url: "/stats" },
];

export default function IndexNavbar() {
  const history = useHistory();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [collapseOut, setCollapseOut] = useState("");
  const [selected, setSelected] = useState(history.location.pathname);

  useEffect(() => {
    history.listen((location) => {
      setSelected(location.pathname);
    });
  }, [history]);

  const gotoPage = (url: string) => {
    history.push(url);
  };

  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };

  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };

  const onCollapseExited = () => {
    setCollapseOut("");
  };

  return (
    <Navbar expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand to="/" tag={Link} id="navbar-brand">
            <img
              alt="logo"
              src={require("assets/img/logo.png")}
              width="auto"
              height="60"
            />
          </NavbarBrand>
          <button
            aria-expanded={collapseOpen}
            className="navbar-toggler navbar-toggler"
            onClick={toggleCollapse}
          >
            {collapseOpen ? (
              <IoIosClose size={45} color="#FD5BB0" />
            ) : (
              <IoIosMenu size={35} color="#FD5BB0" />
            )}
          </button>
        </div>
        <Collapse
          className={"justify-content-end " + collapseOut}
          navbar
          isOpen={collapseOpen}
          onExiting={onCollapseExiting}
          onExited={onCollapseExited}
        >
          <Nav navbar>
            <NavItem className="d-flex flex-col justify-content-between align-items-center">
              <a
                href="https://learn.hex1.club"
                target="_blank"
                rel="noreferrer"
              >
                Learn
              </a>
            </NavItem>
            {menus.map((menu) => {
              return (
                <NavItem
                  key={menu.label}
                  className="d-flex flex-col justify-content-between align-items-center"
                >
                  <button
                    className={selected === menu.url ? "nav-item-selected" : ""}
                    onClick={() => gotoPage(menu.url)}
                  >
                    {menu.label}
                  </button>
                </NavItem>
              );
            })}
            <NavItem>
              <ConnectButton />
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}
