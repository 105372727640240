import React from "react";
interface LoadingContextType {
  show: boolean;
  msg: string;
  showLoading: (msg?: string) => void;
  hideLoading: () => void;
}

export const LoadingContext = React.createContext<LoadingContextType>({
  show: false,
  msg: "",
  showLoading: () => {},
  hideLoading: () => {},
});

export const TimerContext = React.createContext<number>(new Date().getTime());
