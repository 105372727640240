import React, { FC, useEffect, useState } from "react";

import { TimerContext } from "./Contexts";

const TimerProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [timer, setTimer] = useState(new Date().getTime());

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimer(new Date().getTime());
    }, 1000 * 60); // 1 minute

    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <TimerContext.Provider value={timer}>{children}</TimerContext.Provider>
  );
};

export default TimerProvider;
