import { FaGithub, FaTelegram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function Footer() {
  return (
    <footer className="footer">
      <h4 className="text-left mb-0">© 2024 Hex One / All rights reserved</h4>
      <div className="footer-links-container">
        <a href="https://github.com/HexOneProtocol" className="footer-link">
          <FaGithub />
        </a>
        <a href="https://x.com/Hex1crypto" className="footer-link">
          <FaXTwitter />
        </a>
        <a href="https://t.me/HEX1protocol" className="footer-link">
          <FaTelegram />
        </a>
      </div>
    </footer>
  );
}
