import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// providers
import LoadingProvider from "providers/LoadingProvider";
import TimerProvider from "providers/TimerProvider";
import { Web3ModalProvider } from "providers/Web3ModalProvider";

// layout components
import Layout from "components/Layout/Layout";

// modal
import Loading180Ring from "components/Common/Loading180Ring";
import ComingSoon from "views/ComingSoon";

// views
const Landing = lazy(() => import("views/Landing"));
const Borrow = lazy(() => import("views/Borrow"));
const Bootstrap = lazy(() => import("views/Bootstrap"));
const Farming = lazy(() => import("views/Farming"));
const Airdrop = lazy(() => import("views/Airdrop"));
const Stats = lazy(() => import("views/Stats"));
const PriceFeed = lazy(() => import("views/PriceFeed"));

export default function App() {
  return (
    <Web3ModalProvider>
      <LoadingProvider>
        <TimerProvider>
          <Suspense
            fallback={
              <div
                style={{
                  width: "100vw",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading180Ring width={100} height={100} fill="#FFFFFF66" />
              </div>
            }
          >
            <Layout>
              <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/borrow" component={Borrow} />
                <Route exact path="/bootstrap" component={Bootstrap} />
                <Route exact path="/farming" component={Farming} />
                <Route exact path="/airdrop" component={Airdrop} />
                <Route exact path="/stats" component={Stats} />
                <Route exact path="/price-feed" component={PriceFeed} />
                {/* add routes here */}
                <Redirect to="/" />
              </Switch>
            </Layout>
          </Suspense>
        </TimerProvider>
      </LoadingProvider>
    </Web3ModalProvider>
  );
}
