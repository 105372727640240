import React, { FC, useState } from "react";

import { LoadingContext } from "./Contexts";

const LoadingProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState("");

  function showLoading(msg: string | null = null) {
    setMsg(msg || "Loading...");
    setShow(true);
  }

  function hideLoading() {
    setMsg("");
    setShow(false);
  }

  return (
    <LoadingContext.Provider
      value={{
        show,
        msg,
        showLoading,
        hideLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
